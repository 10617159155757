<template>
  <div v-bind="getRootProps()"
       @dragenter.prevent="toggleActive"
       @dragleave.prevent="toggleActive"
       @dragover.prevent
       @drop.prevent="toggleActive"
       :class="{ 'active-dropzone': active }"
       class="dropzone"
       v-if="!this.isUploading"
       id='dropzonearea'
  >
    <div class="dropzone_inside">
      <div class="inside" v-if='!isUploading'>
        <input v-bind="getInputProps()" id="dropzoneFile" class="dropzoneFile" accept="application/pdf"/>
      </div>
    </div>
  </div>
  <div v-if='this.isUploading' class='gauge'>
    <highcharts :p="percentComplete" :h="ht"></highcharts>
  </div>
  
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import { useDropzone } from 'vue3-dropzone';
import MapChart from '../highcharts/solidgauge';
import { v4 as uuidv4 } from 'uuid';
import PSPDFKitContainer from '../pspdfkit/PSPDFKitContainer';

export default {
  name: 'DropZone',
  components: {
    highcharts: MapChart,
    PSPDFKitContainer,
  },
  props: ['docid', 'n','index','height'],
  emits: ['uploadCompleted','uploadStarted'],
  setup(props,context) {
    const percentComplete = ref(0.0);
    const isUploading = ref(false);
    const pdfFile = ref("/pspdfkit-web-demo.pdf");
    let ht = ref(0);
    ht = props.height;
    console.log("Height set in cloudflare comp" + ht);

    function progressHandler(event) {
      let bytes = event.loaded;
      let showbytes = 0;
      let units = '';
      let total = 0;
      let percent = 0;
      if (bytes > 1000) {
        showbytes = bytes / 1000;
        units = 'KB';
      }
      if (bytes > 1000000) {
        showbytes = bytes / 1000000;
        units = 'MB';
      }
      const loaded = `${showbytes.toFixed(2)} ${units}`;

      bytes = event.total;
      showbytes = 0;
      units = '';
      if (bytes > 1000) {
        showbytes = bytes / 1000;
        units = 'KB';
      }
      if (bytes > 1000000) {
        showbytes = bytes / 1000000;
        units = 'MB';
      }
      total = `${showbytes.toFixed(2)} ${units}`;
      percent = (event.loaded / event.total) * 100;
      percentComplete.value = Math.round(percent);
      console.log("PERCENT:",percentComplete.value);
    }

    const completeHandler = (event) => {
      const data = event.target.responseText;
      isUploading.value = false;
      localStorage.class = "pdf_box";
      context.emit("uploadCompleted",0);
    }

    function errorHandler(event) {
      localStorage.class = "pdf_box";
      context.emit("uploadCompleted",-1);
    }

    function abortHandler(event) {
      localStorage.class = "pdf_box";
      context.emit("uploadCompleted",-2);
    }

    async function uploadFile(files) {
      const file = files[0];
      let ext = file.name.split('.').pop();
      if (ext.toLowerCase() !== 'pdf') {
        alert('Wrong file type. You need to choose PDF file');
        return false;
      }

      //todo reuse save in right moment

      const formdata = new FormData();
      const ajax = new XMLHttpRequest();
      const doctype = 'posts';
      let newId = uuidv4();
      if (doctype == null) return false;
      formdata.append('file', file);
      ajax.upload.addEventListener('progress', progressHandler, false);
      ajax.addEventListener('load', completeHandler, false);
      ajax.addEventListener('error', errorHandler, false);
      ajax.addEventListener('abort', abortHandler, false);
      ajax.open('PUT', "https://r2worker.wave7.workers.dev/" + newId + ".pdf");
      ajax.setRequestHeader("X-Custom-Auth-Key", "hotdog");
      ajax.setRequestHeader("Content-type","application/pdf");
      ajax.send(formdata);
      context.emit("uploadStarted","https://r2worker.wave7.workers.dev/" + newId  + ".pdf");

      //return true;
    }

    function onDrop(acceptFiles, rejectReasons) {
      uploadFile(acceptFiles);
      isUploading.value = true;
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };

    return {
      active,
      ht,
      percentComplete,
      isUploading,
      progressHandler,
      completeHandler,
      toggleActive,
      getRootProps,
      getInputProps,
      pdfFile,
      ...rest,
    };
  },
};
</script>

<style lang="scss" scoped>

*{
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.pdf_edit {
  .dropzone_inside {
    .inside {
      // height:200px;
    }
  }
}
.video_edit {
  .dropzone_inside {
    .inside {
      height:200px;
    }
  }
}
.gauge {
  border-radius: 16px;
  border: dashed 2px green;
  height: 71vh;
}
.dropzone {
  border-radius: 16px;
  box-sizing: border-box;
  transition: 0.3s ease all;
  background-image: url(../../../public/img/page/pdf.svg);
  background-size: 160px;
  background-position: top calc(50% - 110px) center;
  background-repeat: no-repeat;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  height: 100%;
  background-color: #fff;
  padding: 16px;
  // max-width: 460px;
  aspect-ratio: 10/16;
  margin: 0 auto;
}
.dropzone::before {
  border: dashed 2px #006C3E26;
  background-position: top calc(50% + 1px) center;
  background-repeat: no-repeat;
  background-image: url(../../../public/img/page/button.svg);
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
}
.dropzone::after {
  content: 'Maximum size: 100MB';
  position:absolute;
  top: calc(50% + 37px);
  left: calc(50% - 100px);
  color: #787878;
  width: 200px;
  height: 32px;
  font-size: 0.76rem;
  line-height: 16px;
  font-weight: 400;
}
.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #41b883;
  label {
    background-color: #fff;
    color: #41b883;
  }
}
@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px)
and (min-height: 961px) and (orientation: portrait){
  .dropzone{
    width: 100%;
    height: 71vh;
    position: initial;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape){
  .dropzone{
    width: 100%;
    height: 71vh;
    background-position: top 130px center;
  }
  .dropzone::before {
    background-position: top 290px center;
  }
  .dropzone::after {
    top: 370px;
  }
}
</style>
